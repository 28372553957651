import { YupTypes } from '@/models/ValidationModel';
import { createValidationObjectSchema } from '@/utils/validations';

export const PlanSchema = createValidationObjectSchema({
  product_key: {
    required: true,
  },
  monthly_pricing_key: {
    required: true,
  },
  // full_monthly_pricing_key: {
  //   required: true,
  // },
  annual_pricing_key: {
    required: true,
  },
  // full_annual_pricing_key: {
  //   required: true,
  // },
  // affiliate_pricing_key: {
  //   required: true,
  // },
  name: {
    required: true,
  },
  // description: {
  //   type: YupTypes.ARRAY
  // },
  code: {
    required: true,
  },
  monthly_price: {
    required: true,
  },
  annual_price: {
    required: true,
  },
});
